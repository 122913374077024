body.qs-home {
  .qs-newsletter-popup {
    padding: 10px 0;
    margin: -40px 0 10px;
    background: #f1f1f2;
    box-shadow: 0 0 14px #bbbaba;

    h3 {
      margin: 0;
    }

    h4 {
      margin-bottom: 20px;
    }
  }
}