body.qs-checkout {
    button[type=submit] {
        width: 155px;
        margin-top: 20px;

        &:disabled {
            background-color: #fff;
            border: 1px solid silver;
        }
    }

    label.error {
        color: red;
    }

    .cc-form {
        padding: 20px 10px;
        border: solid 1px #d0d7de;
    }

    .coupon-savings {
        white-space: nowrap;        
        color: #eb5d68;

        .coupon-code {
            line-height: 17px;

            a {
                font-size: 0.6em;
            }    
        }
    }
}
