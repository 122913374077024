shipping-selector {
  select {
    font-family: "Roboto Condensed" !important;
    font-size: 13.7px !important;
    font-weight: 400 !important;
  }
}

/*********************
MEDIA SPECIFIC
**********************/

.qs-desktop-only {
  display: none !important;
}

.qs-mobile-only {
  display: inline-block !important;
}

@media (min-width: 992px) {
  .qs-desktop-only {
    display: inline-block !important;

    &.qs-block {
      display: block !important;
    }
  }

  .qs-mobile-only {
    display: none !important;
  }
}

a {
  color: #3f444a; }
  a:active, a:hover, a:focus {
    color: #1982e0; }

.c-theme-nav li > a {
  color: #FFFFFF !important;
}

.c-theme-nav li:focus > a:not(.btn), .c-theme-nav li:active > a:not(.btn), .c-theme-nav li:hover > a:not(.btn) {
  color: #5fb0f7 !important; }

.c-theme-btn.btn {
  color: #ffffff;
  background: #1982e0;
  border-color: #1982e0; }
  .c-theme-btn.btn.btn-no-focus:focus, .c-theme-btn.btn.btn-no-focus.focus {
    color: #ffffff;
    background: #1778cf;
    border-color: #1778cf; }
  .c-theme-btn.btn.btn-no-focus:hover, .c-theme-btn.btn:hover, .c-theme-btn.btn.btn-no-focus:active, .c-theme-btn.btn:active, .c-theme-btn.btn.active,
  .open > .c-theme-btn.btn.dropdown-toggle {
    color: #ffffff;
    background: #1778cf;
    border-color: #1778cf; }

.c-quick-search ::placeholder{
  color: #FFFFFF !important;
}

.close-quick-search {
  color: #FFFFFF !important;
}

@media (min-width: 991px) {
  .c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
    color: #FFFFFF !important;
  }}

@media (min-width: 992px) {
.c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
  color: #FFFFFF !important;
}}

@media (max-width: 991px) {
  .c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
    color: #FFFFFF !important;
  }}

@media (max-width: 992px) {
.c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
  color: #FFFFFF !important;
}}

    .btn-lg-primary {
      background: #32bf4c !important;
      border-color: #32bf4c !important;
  }
  
  .btn-lg-primary:hover {
      background: #2fb347 !important;
      border-color: #32bf4c !important;
  }

  .btn-primary {
    color: #ffffff;
    background: #1982e0;
    border-color: #1982e0; }
    .btn-primary.btn-no-focus:focus, .btn-primary.btn-no-focus.focus {
      color: #ffffff;
      background: #1982e0;
      border-color: #1982e0; }
    .btn-primary.btn-no-focus:hover, .btn-primary:hover, .btn-primary.btn-no-focus:active, .btn-primary:active, .btn-primary.active,
    .open > .btn-primary.dropdown-toggle {
      color: #ffffff;
      background: #1982e0;
      border-color: #1982e0; }
    .btn-primary:active, .btn-primary.active,
    .open > .btn-primary.dropdown-toggle {
      background-image: none; }
    .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:not(.btn-no-focus):focus, .btn-primary.disabled:not(.btn-no-focus).focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:not(.btn-no-focus):focus, .btn-primary[disabled]:not(.btn-no-focus).focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
    fieldset[disabled] .btn-primary,
    fieldset[disabled] .btn-primary:hover,
    fieldset[disabled] .btn-primary:not(.btn-no-focus):focus,
    fieldset[disabled] .btn-primary:not(.btn-no-focus).focus,
    fieldset[disabled] .btn-primary:active,
    fieldset[disabled] .btn-primary.active {
      background: #1982e0;
      border-color: #1982e0; }
    .btn-primary .badge {
      color: #1982e0;
      background: #ffffff; }

.c-bg-customBlue {
  background-color: #1778cf;
}

.c-theme-bg-white {
  background-color: #FFF !important;
}

.c-content-pagination.c-theme > li:hover > a {
  border-color: #1982e0 !important;
  background: #1982e0 !important;
  color: #fff; }
  .c-content-pagination.c-theme > li:hover > a > i {
    color: #fff; }

.c-content-pagination.c-theme > li.c-active > span, .c-content-pagination.c-theme > li.c-active > a {
  border-color: #1982e0 !important;
  background: #1982e0 !important;
  color: #fff;
}

.c-theme-ul > li:focus > a:not(.btn), .c-theme-ul > li:active > a:not(.btn), .c-theme-ul > li:hover > a:not(.btn) {
  color: #1982e0 !important;
}

.c-theme-link:focus, .c-theme-link:active, .c-theme-link:hover {
  color: #5fb0f7 !important;
}

.c-theme-on-hover:hover {
  color: #1982e0 !important;
}

.c-content-title-1 > .c-line-right, .c-content-title-1 > .c-line-left {
  width: 30px;
  height: 3px;
  background-color: #1982e0;
  margin: 0 0 30px 0;
}

.c-content-title-1 > .c-line-center {
  width: 30px;
  height: 3px;
  background-color: #1982e0;
  margin: 0 auto 30px auto;
}

.c-theme-bg {
  background: #1982e0 !important;
}

.c-theme-font {
  color: #1982e0 !important;
}

.c-font-customBlue {
  color: #1982e0 !important;
}