body.qs-my-account {
  .c-layout-sidebar-menu {
    width: 210px;
    margin: 0 10px 0 0;
  }

  .c-layout-sidebar-content {
    padding: 0 0 0 30px;
  }

  .orders {
    cursor: pointer;
  }
}
