section.qs-product-grid {
    .qs-product-filters {
        .filter-swatch {
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-right: 3px;
            border: 1px solid #999;
        }
    }

    .qs-page-filters {
        margin-top: 20px;

        .c-filter {
            float: left;
            margin-right: 20px;
        }
    }

    .qs-grid {
        float: left;
        width: 100%;
        margin: 20px -15px 0 -15px;

        .c-bg-img-center {
            height: 180px;
            background-size: contain;
        }

        .c-title {
            height: 44px;
            margin: 0;
        }
    }

    .c-content-pagination {
        margin-bottom: 20px;
    }
}
