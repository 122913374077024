.qs-product-details {
    .rating {
        outline: none;
    }

    #shipping-loader {
        display: none;
    }

    .selected-files {
        margin-top: 15px;

        td {
            &.invalid-file {
                color: #eb5d68;
                text-decoration: line-through;
            }

            &:nth-child(2) {
                width: 25px;
                text-align: center;
            }
        }
    }

    .orSeparator {
        margin: auto 4px !important;
        padding-right: 4px !important;
        font-weight: 500 !important;
    }

    .c-content-tab-3.c-opt-1 .nav>li.active>a {
        background-color: #1982e0 !important;
        color: #FFFFFF !important;
    }

    .c-content-tab-3.c-opt-1 .nav>li:hover>a {
        background-color: #e3e6eb !important;
        color: #1982e0 !important;
    }

    .c-content-tab-3.c-opt-1 .nav>li.active:hover>a {
        background-color: #1778cf !important;
        color: #FFFFFF !important;
    }

    .c-content-tab-3.c-opt-1 .nav>li>a {
        background-color: #edf1f5 !important;
        color: #1982e0 !important;
    }

    .color-swath-selected {
        border: 4px outset #1982e0 !important;
    }
}
