body.qs-shop-cart {
    label.error {
        color: red;
    }
    
    .coupon-code {
        margin-top: 20px;

        input[type=text] {
            margin-left: 20px;
        }

        button {
            padding: 2px 9px;
        }
    }

    .coupon-savings {
        white-space: nowrap;
        
        h3 {
            color: #eb5d68;
        }
    }

    .remove-coupon {
        margin-top: -14px;
        text-align: right;

        a {
            font-size: 0.8em;
        }
    }

    .qs-shop-cart-items {
      .qty-message {
        float: left;
        white-space: nowrap;
        color: red;
      }
    }
}
