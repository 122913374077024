body.qs-design-studio {
  .product-color-selector {
    margin-bottom: 5px;
  }

  .color-selector {
    ul.dropdown-menu {
      height: auto;
      max-height: 200px;
      overflow: hidden scroll;
  
      a {
        color: #fff;
        mix-blend-mode: difference;
  
        &:hover {
          color: inherit;
          mix-blend-mode: normal;
        }
      }
    }  
  }

  .nav-tabs {
    font-size: .8em;
    font-weight: 600;
  }

  .col-sm-6 .nav {
    border-bottom: solid 1px #1982e0;
  }

  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus {
    color: #1982e0;
    background: #FFFFFF;
    border-top-color: #1982e0;
    border-left-color: #1982e0;
    border-right-color: #1982e0;
    border-bottom-color: #FFFFFF;
    cursor: pointer;
  }

  .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover {
    color: #1982e0;
    background: #FFFFFF;
  }

  .nav-tabs>li>a {
    color: #FFFFFF;
    background: #1982e0;
    letter-spacing: 1px;
    border: solid 1px #1982e0;
  }

  .nav-tabs>li>a:hover {
    background: #1778cf;
  }

  .tab-content {
    padding: 10px;
    border: solid 1px #1982e0;
    border-top: transparent;
    border-radius: 0 0 4px 4px;
  }

  .adjustments-toolbar {
    margin-top: 10px;

    .input-group.angle {
      margin-top: -34px;
      margin-left: 236px;

      .disabled {
        color: #9199a2;
        cursor: no-drop;
      }
    }
  }

  .canvas-toolbar {
    position: relative;
    float: left;
    z-index: 1;
    width: 100%;
    padding: 10px 10px 0;

    button {
      margin: 0;
      padding: 0px 10px;
      font-size: 0.8em;
      text-transform: uppercase;

      &.btn-copy-front {
        float: left;
      }
    }

    .face-toolbar{
      float: right;

      button {
        display: block;
        width: 60px;

        &+button {
          margin-top: 5px;
        }
      }
    }
  }

  .adjustments-toolbar,
  .text-designer {
    input[type=number] {
      -moz-appearance: textfield;
      display: inline-block;
      margin: 0; 
      text-align: center;

      &.font-size {
        width: 50px;
      }

      &.line-height {
        width: 60px;
      }

      &.angle {
        width: 61px;
      }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
    }

    .color-selector {
      margin-top: 5px;
    }
  }

  .text-designer {
    article {
      display: block;

      &+article {
        margin-top: 35px;
      }
    }

    .textbox-toolbar {
      float: right;
      font-size: 0.7em;
      font-weight: bold;
      text-transform: uppercase;

      a + a {
        margin-left: 5px;
        color: red;
      }

      footer {
        button + button {
          margin-left: 5px !important;
        }    
      }
    }

    .btn-add-text,
    .btn-update-text {
      padding: 0px 10px;
      margin: 2px 0 0;
      font-size: 0.8em;
      text-transform: uppercase;
    }

    .font-family {
      width: 185px;

      a {
        text-align: left;
      }

      ul.dropdown-menu {
        height: auto;
        max-height: 200px;
        overflow: hidden scroll;    
      }  
    }

    .text-style,
    .text-align {
      button {
        padding: 5px 5px;
      }
    }

    input[type=range] {
      display: inline-block;
      vertical-align: middle;
    }

    &.curved-text {
      .curved-controls {
        label {
          font-size: 14px;

          + label {
            margin-left: 5px;
          }
        }

        .disabled {
          color: #9199a2;
        }
  
        .disabled,
        input[type=range]:disabled {
          cursor: no-drop;
        }

        input[type=radio] {
          vertical-align: text-bottom;
        }

        .color-selector {
          margin-top: 0;
        }    
      }
    }
  }

  .image-designer {
    .image-info {
      .image-toolbar {
        float: right;
        font-size: 0.7em;
        font-weight: bold;
        text-transform: uppercase;
  
        a + a {
          margin-left: 5px;
          color: red;
        }
      }
      
      .details {
        clear: both;
        padding: 10px 0;
        border: solid 1px #ddd;
        border-radius: 4px;

        ul {
          margin: 0;
        }
      }      
    }

    .image-filters {
      margin-top: 10px;

      label {
        font-size: 17px;

        input[type=checkbox] {
          margin-top: 6px;
        }
      }

      input[type=range] {
        margin-top: 12px;
      }
    }

    .imprint-colors {
      margin-top: 10px;

      .col-label {
        padding-top: 5px;
      }

      .alert {
        display: flex;
        flex-direction: row;
        margin: 10px 0 0;
      }
      
      .alert .glyphicon{
        margin-right: 10px;
      }      
    }

    .instructions {
      label {
        width: 100%;
      }  
    }
  }

  .clipart-designer {
    .clipart-info {
      .clipart-toolbar {
        float: right;
        font-size: 0.7em;
        font-weight: bold;
        text-transform: uppercase;
  
        a + a {
          margin-left: 5px;
          color: red;
        }
      }
      
      .details {
        clear: both;
        padding: 10px 0;
        border: solid 1px #ddd;
        border-radius: 4px;

        ul {
          margin: 0;
        }
      }      
    }

    .color-selector {
      margin-top: 5px;
    }

    .breadcrumbs {
      margin: 5px 0 -5px;
    }

    .grid-container {
      margin-top: 10px;
      height: 230px;
      overflow: hidden scroll;

      .grid-thumbnail {
        float: left;
        width: 119px;
        background: #e8e8e8;
        margin: 0 10px 0 0;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;

        &:nth-child(5n) {
          margin-top: 10px;
          margin-left: 0px;
        }

        .grid-thumbnail-image {
          height: 70px;
          border: 4px solid transparent;
          background-color: #fff;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
  
        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 0.7em;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }

  .checkout-form {
    margin-top: 29px;

    h3 {
      margin-top: 0;
      text-transform: uppercase;
    }

    .zipper {
      input[type=checkbox] {
        width: 25px;
      }
    }
  }

  .canvas-wrapper {
    background: #fff;
    overflow: hidden;
    width: 530px;
    height: 530px;
    border: solid 1px #ddd;
    border-radius: 4px;

    .image-cover {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
      z-index: 2;
    }

    .fabric-background {
      height: 530px;
      width: 530px;
      z-index: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      .fabric-layout {
        position: relative;
      }

      .canvas-container {
        border: 1px dashed gray;
      }  
    }

    .image-loading {
      background: rgba(255, 255, 255, .4);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
  
      .loading-indicator {
        background: white;
        background-image: url(../img/content/misc/loader_white_bg.gif);
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
        margin: 185px auto;
        height: 160px;
        width: 160px;
      }
    }
  }

  .c-quote-error {
    margin-left: 15px;
    color: red;
    font-family: "Roboto Condensed", sans-serif;
  }

  .art-colors {
    margin-top: 10px;

    .ac-heading {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .ac-color {
      width: 200px;
      margin-left: 15px;
      padding: 2px 5px;
      border: 1px solid silver;
      border-radius: 3px;

      span {
        color: #fff;
        mix-blend-mode: difference;    
      }

      +.ac-color {
        margin-top: 3px;
      }
    }
  }
}
