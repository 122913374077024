header {
  .login-button {
    float: left;
    margin-top: 10px;

    @media (min-width: 992px) {
      margin-top: -15px;
    }
  }

  .call-us1 {
      margin-left: 15px;
      font-size: .95em;
      display: inline-block;

      a {
          margin-left: 5px;
      }
  }

  .c-mobile-logo {
    margin: -78px 0 0 0 !important;
  }

  .qs-mobile-only {
    &.call-us {
      float: left;
      clear: both;
      margin: -5px 0 0;
    }
  }

  section.qs-navbar {
      .dropdown-menu {
          left: auto !important;
          right: auto !important;
      }
  }

  .site-alert {
    a {
      font-weight: bold;

      &:hover {
        color: #3f444a;
        text-decoration: underline;
      }
    }
  }
}
