body.qs-checkout-complete {
  p.signup {
    a {
      text-decoration: underline;
    }
  }

  .c-content-bar-1 {
    &.c-bordered {
      padding: 20px 20px 90px;
    }
  }

  .total-savings {
    h3,
    span {
      color: #eb5d68;
    }
  }
}
